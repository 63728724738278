import React, { useEffect } from 'react';
import { Link } from "gatsby";
import { IoMdMenu } from "react-icons/io";
import '../styles/style.scss';

const Navbar = ({ locale, pageData }) => {
  useEffect(() => {
    // This is the bulma coder from their documentation. Could be transformed to react-style
    const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
    if ($navbarBurgers.length > 0) {
      $navbarBurgers.forEach( el => {
        el.addEventListener('click', () => {
          const target = el.dataset.target;
          const $target = document.getElementById(target);
          el.classList.toggle('is-active');
          $target.classList.toggle('is-active');
        });
      });
    }
  }, []);

    const PageLinks = () => {
        return <div className="navbar-end">
            {pageData.map(( edge ) => {
                // skip root link and those from other locale + cases (no title)
                if (edge.node.Title === "Index") return '';
                return <Link
                    key={edge.node.slug}
                    to={`/${edge.node.locale}/${edge.node.slug}`}
                    className='navbar-item'
                    activeClassName='active'
                >
                    {edge.node.Title}
                </Link>
            })}
        </div>
    }

    return <nav className="navbar main-navbar">
        <div className="navbar-brand">
            <a role="button" className="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="theNavbarMenu">
                <div>MENU</div>
                <IoMdMenu size={50} />
            </a>
        </div>
        <div id="theNavbarMenu" className="navbar-menu">
            <div className="navbar-start">
                <Link className="navbar-item" activeClassName='active' to={locale === 'de' ? '/' : `/${locale}`}>Startseite</Link>
            </div>
            <PageLinks />
        </div>
    </nav>
};

export default Navbar;
