import React from 'react';
import { graphql, useStaticQuery } from "gatsby"
import ImagesColumn from "./imagesColumn"
import showdown from "showdown"
import '../styles/style.scss';

const Content = ({ locale, markdown, images, noGlossar }) => {
  const data = useStaticQuery(
		graphql`
      query GlossarsWordsQuery {
        allStrapiGlossars {
          edges {
            node {
              id
              Title
              Words
            }
          }
        }
      }
		`
	)

  const content = markdown || ''
  let preparedContent = content;

  if (!noGlossar) {
    const glossars = data.allStrapiGlossars.edges.map(( edge ) => edge.node)
    glossars.forEach(( glossar ) => {
      let words = glossar.Words ? glossar.Words.split(',').map((i) => i.trim()) : []
      words.push(glossar.Title)
      const regEx = new RegExp('(' + words.join('|') + ')', 'ig');
      preparedContent = preparedContent.replace(regEx, (orig) => (
        `[${orig.trim()}](${process.env.BASE_URL}/${locale}/glossar#${glossar.id})`
      ))
    })
  }

  preparedContent = preparedContent.replaceAll("/uploads", `${process.env.API_URL}/uploads`)
  preparedContent = new showdown.Converter().makeHtml(preparedContent)
  preparedContent = preparedContent.replaceAll("<a href", "<a target='_blank' href")

  return (
    <>
      <div className="content">
        <div dangerouslySetInnerHTML={{ __html: preparedContent}}></div>
      </div>
      {images && <ImagesColumn images={images} />}
    </>
  )
}

export default Content;
