import React, { useState } from 'react';
import ImageModal from './imageModal';
import { GatsbyImage } from "gatsby-plugin-image"
import { VscZoomIn } from "react-icons/vsc";

import '../styles/style.scss';

const ImagesColumn = ({ images }) => {
  const [maxedImage, setMaxedImage] = useState();

  const unselectImage = () => {
    setMaxedImage(null);
  }

  const Images = () => {
    return <>
      {maxedImage && <ImageModal image={maxedImage} closeHandler={unselectImage} />}

      <div className="columns is-centered is-multiline">
        {images.map((image) => {
          if (!image.Image) return ''

          let columnClass;
          if (images.length === 1) { columnClass = 'is-half' }
          if (images.length > 3)   { columnClass = 'is-one-quarter' }

          return <div className={`column image-column ${columnClass}`} style={{ position: 'relative' }} key={image.id} onClick={() => setMaxedImage(image)}>
            <VscZoomIn size={22} style={{ color: 'white', position: 'absolute', left: 20, top: 20, zIndex: 30, cursor: 'pointer' }} />
            <GatsbyImage
              image={image.Image.localFile.childImageSharp.gatsbyImageData}
              alt={`Image`}
              style={{ width: '100%' }}
              objectFit={'scale-down'}
            />
          <span className="image-caption">{image.Caption}</span>

          </div>
        })}
      </div>
    </>
  }

  return <Images />
}

export default ImagesColumn;
