import React from 'react';
import {GatsbyImage} from "gatsby-plugin-image"
import '../styles/style.scss';
import Navbar from './navbar';
import { Link } from "gatsby";
import { StaticImage } from 'gatsby-plugin-image'

const Header = ({ siteTitle, locale, isSubpage, headerImageData, pageData }) => {
  const Image = ({ className }) => {
    if (headerImageData) {
      return <GatsbyImage className={className} image={headerImageData} alt={`Image`} style={{ height: 350 }} />
    } else {
      return <StaticImage alt="Bild 1" src="../images/IMG_4720.jpeg" placeholder={"BLURRED"} /> 
    }
  }

  return <>
    <div className = "hero">
      <div className="columns is-vcentered">
        <div className="column is-8">
          <Link to={locale === 'de' ? '/' : `/${locale}`}>
            <h1 className="title is-2">
                <div className="first-small">Wohnen - Arbeiten - Lernen - Lehren am historischen Ort</div>
                <span className="first">ELSE URY CAMPUS</span>
              <br/>
              <span className="second">DOKUMENTATIONSZENTRUM UND GEDENKORT GLEIS17</span>
              <div className="second-small">Dimensionen der Ausgrenzung - Perspektiven der Vermittlung</div>
            </h1>
          </Link>
        </div>
        <div className="column is-4 is-hidden-mobile">
          <Image className="is-pulled-right" />
        </div>
      </div>
    </div>
    <Navbar locale={locale} pageData={pageData} />
    {isSubpage && <Image className="is-hidden-tablet" />}
  </>
};

export default Header;
