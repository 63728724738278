import React, {useEffect} from 'react';
import {useStaticQuery, graphql} from "gatsby";
import bgImg from "../images/background.png"
import '../styles/style.scss';
import Helmet from './helmet';
import Header from './header';
import Footer from './footer';

const Layout = ({ children, locale, isSubpage, headerImageData }) => {
  const data = useStaticQuery(graphql `
  query NavbarQuery {
    allStrapiPages {
        edges {
          node {
            id
            locale
            Title
            show_only_in_footer
            Position
            slug
          }
        }
      }
    }
  `)

  // to assure gray background on page load, change background image not before component mounted
  // otherwise it is white during image load
  useEffect(() => {
    document.documentElement.style.backgroundImage = `url(${bgImg})`;
  }, []);

  const pageData = data.allStrapiPages.edges.filter((edge) => edge.node.locale === locale)
    .sort((a, b) => (a.node.Position > b.node.Position) ? 1 : -1)

  const headerPageData = pageData.filter((edge) => !edge.node.show_only_in_footer)

  return <div className="main-container container">
    <Helmet/>
    <Header pageData={headerPageData} locale={locale} isSubpage={isSubpage} headerImageData={headerImageData}/>
    {children}
    <Footer pageData={pageData} locale={locale}/>
    {/* Matomo Image Tracker */}
    <img referrerpolicy="no-referrer-when-downgrade" src="https://coderat.matomo.cloud/matomo.php?idsite=4&amp;rec=1" style={{ border:0 }} alt="" />
    {/* End Matomo */}
  </div>
};

export default Layout;
