import React from 'react'
import { Link } from "gatsby";
import '../styles/style.scss'
import logo from "../images/logo.png"

const Footer = ({ locale, pageData }) => (
  <section className='section footer'>
    <div className='container'>
    <div className='columns'>
      <div className='column is-half has-text-centered footer-left'>
        <div>
          <Link to={process.env.API_URL + '/admin'}>Admin</Link>

            {pageData.filter((edge) => edge.node.show_only_in_footer).map(( edge ) => (
              <Link
                key={edge.node.slug}
                to={`/${edge.node.locale}/${edge.node.slug}`}
              >
                {edge.node.Title}
              </Link>
            ))}
          <br/>

          <a href="https://www.moses-mendelssohn-stiftung.de/" target="_blank">
            <img className="footer-logo" src={logo} />
          </a>
        </div>
      </div>

      <div className='column is-half has-text-centered footer-right'>
        {pageData.filter((edge) => !edge.node.show_only_in_footer).map(( edge ) => {
          if (edge.node.Title === "Index") return '';

          return <div className='footer-link'>
            <Link
              key={edge.node.slug}
              to={`/${edge.node.locale}/${edge.node.slug}`}
              activeClassName='active'
            >
              {edge.node.Title === "Index" ? 'Start' : edge.node.Title}
            </Link>
          </div>
        })}
      </div>
    </div>
    </div>
  </section>
)

export default Footer
