import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import ogImage from '../../static/og_image.jpeg'

const Metadata = () => (
  <StaticQuery
    query={graphql`
            query helmetQuery {
                site {
                    siteMetadata {
                        title
                        author
                        imageUrl
                        description
                        keywords
                    }
                }
            }
        `}
    render={data => (
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=0.8, user-scalable=0"
        />
        <meta name="keywords" content={data.site.siteMetadata.keywords} />
        <title>{data.site.siteMetadata.title}</title>
        <html lang="de" />
        {/* Google / Search Engine Meta Tags */}
        <meta itemprop="name" content={data.site.siteMetadata.author} />
        <meta
          itemprop="description"
          content={data.site.siteMetadata.description}
        />

        <meta name="google-site-verification" content="lP7U9ERcvxB6ZnYRnWwNF1QFIL1eDoVxbTChSGzWeb4" />

        <meta property="og:site_name" content={data.site.siteMetadata.title} />
        <meta property="og:title" content={data.site.siteMetadata.title} />
        <meta property="og:description" content={data.site.siteMetadata.description} />
        <meta property="og:image" content={process.env.BASE_URL + ogImage} />

        <meta property="og:type" content="website" />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:secure_url" content={process.env.BASE_URL + ogImage} />

        <meta property="og:image:width" content="256" />
        <meta property="og:image:height" content="192" />

        <meta property="og:url" content={process.env.BASE_URL} />
      </Helmet>
    )}
  />
);

export default Metadata
