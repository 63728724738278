import React from 'react';
import { GatsbyImage } from "gatsby-plugin-image"

const ImageModal = ({ image, closeHandler }) => {
  return (
    <div className="modal is-active" onClick={closeHandler} onKeyDown={closeHandler}>
      <div className="modal-background" role="button" tabIndex={0}></div>
      <div className="modal-card">
        <section className="modal-card-body">
          <GatsbyImage
            image={image.Image.localFile.childImageSharp.gatsbyImageData}
            alt={`Image`}
            style={{ width: '100%' }}
            objectFit={'cover'}
          />
        <span className="image-caption">{image.Caption}</span>
        </section>
      </div>
    </div>
  );
}

export default ImageModal;
